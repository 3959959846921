import React from 'react'
import './css/navigationmenustyle.css'
import imglinkedin from './images/linkedinlogo.png'
import imgtwitter from './images/twitterlogo.png'
import imginstagram from './images/instagramlogo.png'

export default function navigationmenu() {
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navigationcontainer").style.top = "0px";
  } else {
    document.getElementById("navigationcontainer").style.top = "-115px";
  }
  prevScrollpos = currentScrollPos;
  }

  function scrollHome() {
    clicMobileMenu ();
    document.getElementById("checkbox1").checked = false;
    window.scrollTo(0,0);
  }

  function scrollProjects() {
    clicMobileMenu ();
    document.getElementById("checkbox1").checked = false;
    window.scrollTo(0,750);
  }

  function scrollAboutMe() {
    clicMobileMenu ();
    document.getElementById("checkbox1").checked = false;
    window.scrollTo(0,(document.body.scrollHeight)-1750);
  }

  function scrollContactMe() {
    clicMobileMenu ();
    document.getElementById("checkbox1").checked = false;
    window.scrollTo(0,(document.body.scrollHeight)-200);
  }

  var mobilemenu = false;

  function clicMobileMenu (){
    if (mobilemenu==false) {
      document.getElementById("navigationcontainermobile").style.transform = "translateY(0px)";
      //document.getElementById("navigationcontainermobile").style.display = "flex";
      mobilemenu = true;
    } else {
      document.getElementById("navigationcontainermobile").style.transform = "translateY(-100%)";
      //document.getElementById("navigationcontainermobile").style.display = "none";
      mobilemenu = false;
    }
  }

  return (
    <div>

    <div id="navigationcontainer">
        <ul id="navigationlist">
            <li class="linav" id="liHome"><a onClick={()=>scrollHome()} class="active" href="#home">Home</a></li>
            <li class="linav"><a onClick={()=>scrollProjects()} href="#Projects">My Projects</a></li>
            <li class="linav"><a onClick={()=>scrollAboutMe()} href="#AboutMe" >About Me</a></li>
            <li class="linav"><a onClick={()=>scrollContactMe()} href="#ContactMe" >Contact Me</a></li>
            <li class="linav"><a href="https://drive.google.com/drive/folders/1EBIBf6ZAGsJolQmNHYecrObzigWticp6?usp=share_link" target="_blank">Resume</a></li>
        </ul>
    </div>

    <div id="mobilemenucontainer">
      <div class="hambcontainer">
        <input type="checkbox" id="checkbox1" class="checkbox1 visuallyHidden"/>
        <label for="checkbox1">
          <div class="hamburger hamburger1" onClick={()=>clicMobileMenu()}>
            <span class="bar bar1"></span>
            <span class="bar bar2"></span>
            <span class="bar bar3"></span>
            <span class="bar bar4"></span>
          </div>
        </label>
      </div>
    </div>

    <div id="navigationcontainermobile">
        <ul id="navigationlistmobile">
            <li class="linavmov" id="liHome"><a onClick={()=>scrollHome()} class="active amobilelist" href="#home">Home</a></li>
            <li class="linavmov"><a class="amobilelist" onClick={()=>scrollProjects()} href="#Projects">My Projects</a></li>
            <li class="linavmov"><a class="amobilelist" onClick={()=>scrollAboutMe()} href="#AboutMe" >About Me</a></li>
            <li class="linavmov"><a class="amobilelist" onClick={()=>scrollContactMe()} href="#ContactMe" >Contact Me</a></li>
            <li class="linavmov"><a class="amobilelist" href="https://drive.google.com/drive/folders/1EBIBf6ZAGsJolQmNHYecrObzigWticp6?usp=share_link" target="_blank">Resume</a></li>
        </ul>
        <div id="socialmediacontainermobile">
          <a href="https://www.linkedin.com/in/steven-morales-jara-270876262/" target="_blank" class="smhrefl"><img src={imglinkedin} class="smimg" id="imglinkedin"/></a>
          <a href="https://twitter.com/StevenHexaLabs" target="_blank" class="smhrefl"><img src={imgtwitter} class="smimg" id="imglinkedin"/></a>
          <a href="https://www.instagram.com/stevenhexalabs/" target="_blank" class="smhrefl"><img src={imginstagram} class="smimg" id="imglinkedin"/></a>
        </div>
    </div>

    <div id="socialmediacontainer">
    <a href="https://www.linkedin.com/in/steven-morales-jara-270876262/" target="_blank" class="smhrefl"><img src={imglinkedin} class="smimg" id="imglinkedin"/></a>
    <a href="https://twitter.com/StevenHexaLabs" target="_blank" class="smhrefl"><img src={imgtwitter} class="smimg" id="imglinkedin"/></a>
    <a href="https://www.instagram.com/stevenhexalabs/" target="_blank" class="smhrefl"><img src={imginstagram} class="smimg" id="imglinkedin"/></a>
  </div>
  </div>
  )
}
//<li class="linav"><a href="#contact">Contact</a></li>