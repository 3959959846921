import React from 'react'
import './css/generalstyle.css'
import './css/project-style.css'
import './css/projectsstyle.css'
import imgprj1 from './images/imgprj1.PNG'
import imgprj2 from './images/imgprj2.PNG'
import imgprj3 from './images/imgprj3.PNG'
import imgprj4 from './images/imgprj4.PNG'
//import display from './scriptsjs/projects.js'
//import ScriptTag from 'react-script-tag';

export default function Projects() {
    var displayboolean = false;

function display(input) {
    if (displayboolean == false) {
        document.getElementsByClassName('projectmoreinfo')[input].style.padding = '20px';
        document.getElementsByClassName('projectmoreinfo')[input].style.height = 'fit-content';
        document.getElementsByClassName('displaybutton')[input].innerText  = 'View less';
    } else {
        document.getElementsByClassName('projectmoreinfo')[input].style.padding = '0px';
        document.getElementsByClassName('projectmoreinfo')[input].style.height = '0px';
        document.getElementsByClassName('displaybutton')[input].innerText  = 'View more';
    }
    displayboolean = !displayboolean;
}

  return (
    <div id="projects_container">
      <h1 id="projects_title">My Projects</h1>
      <div id="projects_list_container">

    <div class="projectcontainer" id="project1">
        <h2 class="projecttile" id="project1title">HexaLabs Portfolio</h2>
        <div class="projectbasicinfo" id="project1basicinfo">
            <p class="projectgeneralinfo" id="project1generalinfo">+ Type: Front-End Website<br />+ IDE: Visual Studio Code<br />+ Framework: React<br />+ Languages: JSX, JavaScript, CSS and HTML<br />+ Description: This is the website you are currently on</p>
            <button class="medium-button displaybutton" id="project1viewbutton" onClick={()=>display(0)}>View more</button>
        </div>
        <div class="projectmoreinfo" id="project1moreinfo">
            <p id="a">The website was made with the React framework, it was made to serve as my personal portfolio to show my projects and my abilities</p>
            <div class="video_wrapper">
            <img src={imgprj1} class="imgprj" id="imgprj1"/>
            </div>
            <a class="projecthref" href="https://hexalabs.pages.dev" target="_blank"><button class="medium-button gotoprojectspage">Go to projects page</button></a>
        </div>
    </div>

    <div class="projectcontainer" id="project2">
        <h2 class="projecttile" id="project2title">El Noveno Círculo</h2>
        <div class="projectbasicinfo" id="project2basicinfo">
            <p class="projectgeneralinfo" id="project2generalinfo">+ Type: Front-End Website<br />+ IDE: Visual Studio Code<br />+ Framework: React<br />+ Languages: JSX, JavaScript, CSS and HTML<br />+ Description: A music band website</p>
            <button class="medium-button displaybutton" id="project2viewbutton" onClick={()=>display(1)}>View more</button>
        </div>
        <div class="projectmoreinfo" id="project2moreinfo">
            <p id="a">The website was made with the React framework, it was made to serve as the official El Noveno Círculo's band website</p>
            <div class="video_wrapper">
            <img src={imgprj2} class="imgprj" id="imgprj2"/>
            </div>
            <a class="projecthref" href="https://www.elnovenocirculo.tk" target="_blank"><button class="medium-button gotoprojectspage">Go to projects page</button></a>
        </div>
    </div>

    <div class="projectcontainer" id="project3">
        <h2 class="projecttile" id="project3title">ShortCut Shop Website</h2>
        <div class="projectbasicinfo" id="project3basicinfo">
            <p class="projectgeneralinfo" id="project3generalinfo">+ Type: Full-Stack Website<br />+ IDE: Visual Studio Code<br />+ Framework: No framework<br />+ Languages: PHP, JavaScript, CSS and HTML<br />+ Description: A shop website made without framework</p>
            <button class="medium-button displaybutton" id="project3viewbutton" onClick={()=>display(2)}>View more</button>
        </div>
        <div class="projectmoreinfo" id="project3moreinfo">
            <p id="a">The website was made entirely by hand using PHP and other tools, this was the first website I made and with this project I learned about web developing by my-self</p>
            <div class="video_wrapper">
            <img src={imgprj3} class="imgprj" id="imgprj3"/>
            </div>
            {/* <a class="projecthref" href="https://www.elnovenocirculo.tk" target="_blank"><button class="medium-button gotoprojectspage">Go to projects page</button></a> */}
        </div>
    </div>


    <div class="projectcontainer" id="project4">
        <h2 class="projecttile" id="project4title">ShortCut Administration Software</h2>
        <div class="projectbasicinfo" id="project4basicinfo">
            <p class="projectgeneralinfo" id="project4generalinfo">+ Type: Desktop Application<br />+ IDE: Visual Studio<br />+ Languages: C# and SQL<br />+ Description: A decision support system</p>
            <button class="medium-button displaybutton" id="project4viewbutton" onClick={()=>display(3)}>View more</button>
        </div>
        <div class="projectmoreinfo" id="project4moreinfo">
            <p id="a">In this project I learned C# and created this desktop application to manage the products from the database of the company. It was also made for being a decision support system</p>
            <div class="video_wrapper">
            <img src={imgprj4} class="imgprj" id="imgprj4"/>
            </div>
            {/* <a class="projecthref" href="https://www.elnovenocirculo.tk" target="_blank"><button class="medium-button gotoprojectspage">Go to projects page</button></a> */}
        </div>
    </div>


      </div>
    </div>
  )
}
//<iframe allowfullscreen class="videoiframe" src="https://www.youtube.com/embed/qSqDx1YCEtg?autoplay=0&mute=0"></iframe>