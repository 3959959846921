import React from 'react'
import './css/contactmestyle.css'

export default function ContactMe() {
    
  return (
    <div id="contactmegeneralcontainer">
        <h1 id="contactme_title">Contact Me</h1>
        <div id="formcontainer">
        <form action="https://formspree.io/f/xnqynrqp" method="POST" />

       
<form id='formspree'
  action="https://formspree.io/f/xnqynrqp"
  method="POST"
>
  <label className='formtext'>
    Your email:
    <input type="email" name="email" className='inputbox'/>
  </label>
  <label className='formtext'>
    Your Name:
    <input type="text" name="name" className='inputbox'/>
  </label>
  <label className='formtext'>
    Your message:
    <textarea name="message" className='inputbox'></textarea>
  </label>
  <button type="submit" className='medium-button' id="senbutton">Send Email</button>
</form>
        </div>
    </div>
  )
}
