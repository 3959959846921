import React from 'react'
import Navigationmenu from './Navigationmenu';
import Main from './Main';
import Projects from './Projects';
import Aboutme from './Aboutme';
import ContactMe from './ContactMe';

function App() {
  return (
    <>
    <br className='mobilejump'/><br className='mobilejump'/>
      <Navigationmenu />
     <Main />
     <Projects />
     <Aboutme />
     <ContactMe />
    </>
  )
}

export default App;
