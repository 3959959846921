import React from 'react'
import './css/aboutmestyle.css'
import me from './images/stevenfoto.png'

export default function Aboutme() {
  return (
    <div id="aboutmegeneralcontainer">
        <h1 id="aboutme_title">About Me</h1>
      <div id="aboutmecontainer">
        <p id="aboutmeinfo">My name is Steven, I enjoy coding front end interactive websites. I also make desktop applications. My interest comes from the fact that I always liked to ask myself how little things worked, so I became interested on how web UIs were actually made.<br /><br />
        I decided studying a bachelor's degree “Informática Empresarial” or “Business Computing”. I am currently in the 3rd year of my career and they have taught all the basics of computing science. Once I got enough knowledge I started making websites using React and Wordpress.<br /><br />
        I have also made desktop applications for Business using Java and C#.</p>
        <img src={me} id="steven_image"/>
      </div>
    </div>
  )
}
